import React, { Component } from "react";

import { multiLanguage } from "../Language/getBahasa";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

type Props = {
  url: any,
  type: String,
  name: String,
};

class ButtonBack extends Component<Props> {
  render() {
    const { url, type, name } = this.props;
    return type === "back" ? (
      <a href={url} title={multiLanguage.back}>
        <Button type="submit" size="md" className="btn-primary-sm">
          <i className="fa fa-chevron-left"> </i>
          {"Login Page"}
        </Button>
      </a>
    ) : (
      ""
    );
  }
}

export default ButtonBack;
