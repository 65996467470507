import { baseUrl, path_users } from "../components/config/config";
import axios from "axios";

export function PostData(type, data) {
  const FETCH_TIMEOUT = 30000;
  let didTimeOut = false;
  let config = baseUrl + path_users;

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(function () {
      didTimeOut = true;
      reject(new Error("Request Time Out"));
    }, FETCH_TIMEOUT);

    fetch(config + type, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        clearTimeout(timeout);
        if (!didTimeOut) {
          resolve(responseJson);
        }
      })
      .catch((error) => {
        if (didTimeOut) return;
        alert("Silahkan coba beberapa saat lagi");
        reject(error);
      });
  }).catch(function (err) {
    alert("Not Internet Connection");
    window.location.reload();
  });
}

export const PostRegister = async (strUrl, body) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: baseUrl + strUrl,
      headers: {
        "Content-Type": "aplication/json",
      },
      data: body,
    };
    axios(config)
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);
          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};
export const PostRegisterAsesi = async (strUrl, body) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: baseUrl + strUrl,
      headers: {
        "Content-Type": "aplication/json",
      },
      data: body,
    };
    axios(config)
      .then((res) => {
        console.log("axios re", res);
        if (res.status === 200) {
          resolve(res);
          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};
