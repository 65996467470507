import React from "react";
import { Upload, Icon, Button } from "antd";

function getBase64(file, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(file);
  reader.fileName = file.name;
}

class UploadPicture extends React.Component {
  state = {
    loading: false,
  };

  customRequest = (info) => {
    this.setState({ loading: true });
    if (info.file) {
      getBase64(info.file, (base64) => {
        let filename = info.file.name;
        let filesize = info.file.size;
        let upload = { filename, base64,filesize };
        this.setState({ loading: false });
        this.props.upload(upload);
        info.onSuccess(true);
      });
    }
  };

  render() {
    const uploadButton = (
      <Button>
        <div className="upload">
          <Icon type="upload" /> Upload
        </div>
      </Button>
    );

    return (
      <Upload
        accept=".jpeg,.png,.tiff,.jpg"
        showUploadList={false}
        customRequest={this.customRequest}
        onRemove={this.props.onRemove}
      >
        {uploadButton}
      </Upload>
    );
  }
}

export { UploadPicture };
