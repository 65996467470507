import React from "react";
import DefaultLayout from "./containers/DefaultLayout/DefaultLayout";
import "../src/css/loaderComponent.css";
import { multiLanguage } from "./components/Language/getBahasa";


const Assessors = React.lazy(() => import('./views/Asesor/Asesors'));
const GradingAcesor = React.lazy(() => import('./views/Grading/Grading'));
const AddGradingAcesor = React.lazy(() => import('./components/InputData/Input_Grading_Asesor'));
const EditProfile = React.lazy(() => import('./views/EditProfile/editProfile'));
const EditGradingAcesor = React.lazy(() => import('./components/EditData/Edit_Grading_Asesor'));
const Applicant = React.lazy(() => import('./views/Applicant/Applicant'));
const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));
const SubSchema = React.lazy(() => import('./views/Competences/SubSchema/SubSchema'));
const EditAssessors = React.lazy(() => import('./components/EditData/EditData_acceccors'));
const EditApplicant = React.lazy(() => import('./components/EditData/EditData_applicant'));

const EditData = React.lazy(() => import('./components/EditData/EditData'));
const EditDataUserAcessor = React.lazy(() => import('./components/EditData/EditDataPengguna/EditDataUserAcessor'));
const EditDataUserAplicant = React.lazy(() => import('./components/EditData/EditDataPengguna/EditDataUserAplicant'));
const EditDataUserAdminTuk = React.lazy(() => import('./components/EditData/EditDataPengguna/EditDataUserAdminTuk'));
const EditDataUserManagements = React.lazy(() => import('./components/EditData/EditDataPengguna/EditDataUserManagement'));
const EditData_subSchema = React.lazy(() => import('./components/EditData/EditData_subSchema'));
const EditData_mainSchema = React.lazy(() => import('./components/EditData/EditData_mainSchema'));
const EditTuk = React.lazy(() => import('./components/EditData/EditData_tuk'));
const MainSchema = React.lazy(() => import('./views/Competences/MainSchema/MainSchema'));
const InputData = React.lazy(() => import('./components/InputData/InputData'));

const InputData_subSchema = React.lazy(() => import('./components/InputData/InputData_subSchema'));
const InputData_mainSchema = React.lazy(() => import('./components/InputData/InputData_mainSchema'));
const InputData_tuk = React.lazy(() => import('./components/InputData/InputData_tuk'));
const ListSkill = React.lazy(() => import('./views/Asesor/ListSkill'));
const InputData_Vehicles = React.lazy(() => import('./components/InputData/InputData_Vehicles'));
const PendingCompetance = React.lazy(() => import('./views/Asesor/PendingCompetance'));
const AssesmenSumary = React.lazy(() => import('./views/Schedule/AssesmentSumary'));
const Schedule = React.lazy(() => import('./views/Schedule/Schedule'));
const JoinAsesment = React.lazy(() => import('./views/Schedule/JoinAssesment'));
const JoinSkema = React.lazy(() => import('./views/Schedule/JoinSkema'));

const InputData_JoinSkema = React.lazy(() => import('./components/InputData/Input_Join_Skema'));
const Submissions = React.lazy(() => import('./views/Schedule/Submissions'));
const InputAssessment = React.lazy(() => import('./components/InputData/InputData_Assessment'));
const Schedule_accessors = React.lazy(() => import('./views/Schedule/Schedule_accessors'));
const TUK = React.lazy(() => import('./views/TUK/TUK'));
const Users = React.lazy(() => import('./views/Users/Users'));
const NotFound = React.lazy(() => import('./views/Pages/Page404/Page404'));
const News = React.lazy(() => import('./views/Pages/News/NewsWebViews'));
const AssignApplicant = React.lazy(() => import('./views/Schedule/AssignApplicant'));
const AssignAssessors = React.lazy(() => import('./views/Schedule/AssignAssessors'));
// ============== from her ================//
const AssignAdmin = React.lazy(() => import('./views/Schedule/AssignAdmin'));
const Assign = React.lazy(() => import('./components/Detail/Detail.js'));
const AssignDetailArchive = React.lazy(() => import('./components/Detail/DetailArchive'));
const Portofolio = React.lazy(() => import('./components/Detail/Portofolio'));
const PortofolioKhusus = React.lazy(() => import('./components/Detail/PortofolioKhusus'));
const PortofolioAsesi = React.lazy(() => import('./views/Schedule/PortofolioRoleAsesiNew'));
const PortofolioAsesorKhusus = React.lazy(() => import('./components/Detail/PortoFolioKhususAsesor'));
const PortofolioAsesor = React.lazy(() => import('./components/Detail/Portofolio_Asessor'));
const PortofolioPeserta = React.lazy(() => import('./components/Detail/PortofolioPeserta'));
const Notif = React.lazy(() => import('./components/Notification/Notification'));

const AssignPleno = React.lazy(() => import('./components/InputData/InputData_Plenos'));
const GenerateLetters = React.lazy(() => import('./views/Correspondence/Letters'));
const Alumni = React.lazy(() => import('./views/Alumni/Alumni'));
const InputAlumni = React.lazy(() => import('./components/InputData/InputData_alumni'));
const EditData_Vehicles = React.lazy(() => import('./components/EditData/EditData_vehicles'));
const EditAlumni = React.lazy(() => import('./components/EditData/EditData_alumni'));
const CertificateAlumni = React.lazy(() => import('./views/Alumni/CertificateComptence'));
const EditLogBook = React.lazy(() => import('./components/EditData/EditData_LogBook'));
const Portfolios = React.lazy(() => import('./views/MasterData/Portfolios'));
const Portfolios_Umum = React.lazy(() => import('./views/MasterData/PortfoliosUmum'));

const Master_Alat_Berat = React.lazy(() => import('./views/Vehicles/Vehicles'));
const Institute = React.lazy(() => import('./views/Institute/Institute'));
const AddInstitute = React.lazy(() => import('./views/Institute/AddInstitute'));
const EditInstitute = React.lazy(() => import('./views/Institute/AddInstitute'));
const Materi_Uji = React.lazy(() => import('./views/MateriUji/Materi_Uji'));
const InputData_Portfolios = React.lazy(() => import('./components/InputData/Input_Persyratan_Khusus'));
const Input_Portfolios_umum = React.lazy(() => import('./components/InputData/Input_Persyaratan_Umum'));
const Input_Materi_Uji = React.lazy(() => import('./components/InputData/Input_Materi_Uji'));
const EditData_portfoliosMaster = React.lazy(() => import('./components/EditData/EditData_portfoliosMaster'));
const Edit_portfoliosMasterUmum = React.lazy(() => import('./components/EditData/Edit_portfoliosMasterUmum'));

const Edit_portfoliosMasterKhusus = React.lazy(() => import('./components/EditData/Edit_portfoliosMasterUmum'));
const Edit_portfoliosMasterMateriUji = React.lazy(() => import('./components/EditData/Edit_portfoliosMasterUmum'));
const DetailAssessmentArchive = React.lazy(() => import('./views/Schedule/DetailAsessmentArchive'));
const NoSurat = React.lazy(() => import('./components/EditData/EditNoSurat'));
const Rejected = React.lazy(() => import('./views/Schedule/Rejected'));
const ManagementSurat = React.lazy(() => import('./views/ManageSurat/ManageSurat'));
const AsesiUnderAssessors = React.lazy(() => import('./views/Schedule/DetailAsesiAssessment'));
const AsesiAssignAsesors = React.lazy(() => import('./views/Schedule/DetailAssesmentAsesor'));
const AsesiUnderAssessment = React.lazy(() => import('./views/Schedule/AsesiReadyAssign'));
const PortfolioRoleAsesi = React.lazy(() => import('./views/Schedule/portfolioRoleAsesi'));

const Archive = React.lazy(() => import('./views/Schedule/ArchiveAssessment'));
const UnitCompetention = React.lazy(() => import('./views/Competences/UnitCompetention/UnitCompetention'));
const PersyaratanUmum = React.lazy(() => import('./components/Detail/PersyaratanUmum'));
const RestoreData = React.lazy(() => import('./views/RestoreData/RestoreData'));
const LogBook = React.lazy(() => import('./views/LogBook/LogBook'));
const InputLogBook = React.lazy(() => import('./views/LogBook/AddLogBook'));
const EditLogBookAsesi = React.lazy(() => import('./views/LogBook/EditLogBook'));

const routes = [
  //menu user managemen
  { path: "/users", exact: true, name: "Users", component: Users },
  {
    path: "/users/add-users",
    name: `${multiLanguage.add} Data`,
    component: InputData,
    exact: true
  },
  { path: "/users/edit-users/:user_id", component: EditData,exact: true },
  {
    path: "/users/edit-users-accessor/:user_id",
    component: EditDataUserAcessor,
    exact: true
  },
  {
    path: "/users/edit-users-aplicant/:user_id",
    component: EditDataUserAplicant,
    exact: true
  },
  {
    path: "/users/edit-users-admintuk/:user_id",
    component: EditDataUserAdminTuk,
    exact: true
  },
  {
    path: "/users/edit-users-managements/:user_id",
    component: EditDataUserManagements,
    exact: true
  },
  {
    path: "/users/:user_id/Asesors",
    name: `${multiLanguage.Edit} Data Asesor`,
    component: EditAssessors,
    exact: true
  },
  //menu accessors
  { path: "/Assessors", exact: true, name: "Asesor", component: Assessors, exact: true },
  { path: "/Assessors/list-skill/:user_id", component: ListSkill, exact: true },
  {
    path: "/Assessors/pending-competance",
    name: `${multiLanguage.competencePending}`,
    component: PendingCompetance,
    exact: true
  },
  {
    path: "/Assessors/schedule_accessors",
    name: `${multiLanguage.schedule} Asesor`,
    component: Schedule_accessors,
    exact: true
  },

  //menu applicant
  { path: "/asesi", exact: true, name: "Asesi", component: Applicant,exact: true },
  {
    path: "/asesi/edit-asesi/:user_id",
    name: `${multiLanguage.Edit} Asesi`,
    component: EditApplicant,
    exact: true
  },

  //menu schema
  {
    path: "/schema/main-schema",
    exact: true,
    name: multiLanguage.mainSchema,
    component: MainSchema,
  },
  {
    path: "/schema/main-schema/edit-mainSchema/:schema_id",
    component: EditData_mainSchema,
    name: `${multiLanguage.Edit} ${multiLanguage.mainSchema}`,
    exact: true
  },
  {
    path: "/schema/main-schema/add-mainCompetence",
    name: `${multiLanguage.add} Data`,
    component: InputData_mainSchema,
    exact: true
  },
  {
    path: "/schema/sub-schema",
    exact: true,
    name: multiLanguage.subSchema,
    component: SubSchema,
  },
  {
    path: "/Schema/:schema_id/sub_schemas/:sub_schema_id/:sub_schema_number",
    name: `${multiLanguage.Edit} data ${multiLanguage.subSchema}`,
    component: EditData_subSchema,
    exact: true
  },
  {
    path: "/schema/sub-schema/add-subSchema",
    name: `${multiLanguage.add} Data`,
    component: InputData_subSchema,
  },
  {
    path: "/schema/unit-competention",
    exact: true,
    name: `${multiLanguage.UnitCompetention}`,
    component: UnitCompetention,
  },

  //menu assessment

  {
    path: "/assessments/dashboard",
    exact: true,
    name: "Assessments Sumary",
    component: AssesmenSumary,
  },
  {
    path: "/assessments/list",
    exact: true,
    name: "Assessments",
    component: Schedule,
  },
  {
    path: "/join-assesment",
    exact: true,
    name: "Join Assesment",
    component: JoinAsesment,
  },

  {
    path: "/join-skema",
    exact: true,
    name: "Join Skema",
    component: JoinSkema,
  },
  {
    path: "/join-skema/add-join-skema",
    name: `${multiLanguage.add} Data JOin Skema`,
    component: InputData_JoinSkema,
    exact: true
  },
  {
    path: "/assessments/input-data",
    name: `${multiLanguage.add} ${multiLanguage.schedule} Assessment`,
    component: InputAssessment,
    exact: true
  },
  {
    path: "/assessments/:assessment_id/applicants/:assessment_applicant_id/portofolio",
    name: "Portofolio Asesi",
    component: Portofolio,
    exact: true
  },
  {
    path: "/assessments/:sub_schema_id/applicants/:applicant_id/:assessment_applicant_id/portofolio-khusus",
    name: "Portofolio Asesi",
    component: PortofolioKhusus,
    exact: true
  },
  {
    path: "/assessments/:assessment_id/portofolio-asesi",
    name: "Portofolio Asesi",
    component: PortofolioAsesi,
    exact: true
  },

  {
    path: "/assessments/:assessment_id/:sub_schema_id/applicants/:applicant_id/:assessment_applicant_id/portofolio-asessor-khusus",
    name: "Portofolio Asesi",
    component: PortofolioAsesorKhusus,
    exact: true
  },
  {
    path: "/assessments/:assessment_id/applicants/:assessment_applicant_id/portofolio-assesor",
    name: "Portofolio Asesi",
    component: PortofolioAsesor,
    exact: true
  },
  {
    path: "/applicants/:applicant_id/:sub_schema_id/persyaratan-peserta",
    name: "Portofolio Asesi",
    component: PortofolioPeserta,
  },
  {
    path: "/assessments/:assessment_id/assign",
    name: "Assign Assessment",
    exact: true,
    component: Assign,
  },
  {
    path: "/assessments/:assessment_id/assign_archive",
    name: "Assign Assessment",
    exact: true,
    component: AssignDetailArchive,
  },
  {
    path: "/assessments/:assessment_id/assign-admin/:run",
    component: AssignAdmin,
    exact: true
  },
  {
    path: "/assessments/:assessment_id/assign-applicant/:sub_schema_id/:run",
    component: AssignApplicant,
    exact: true
  },
  {
    path: "/assessments/:assessment_id/generate",
    component: GenerateLetters,
    exact: true
  },
  {
    path: "/assessments/:assessment_id/assign-assessors",
    component: AssignAssessors,
    exact: true
  },
  {
    path: "/assessments/:assessment_id/detail",
    name: "Detail Assessment",
    component: DetailAssessmentArchive,
    exact: true
  },
  {
    path: "/assessments/:assessment_id/assign/:assessor_id/:is_user_pleno",
    name: "Detail Asesi on Assessments",
    component: AsesiAssignAsesors,
    exact: true
  },

  {
    path: "/assessments/:assessment_id/assign/:assessor_id",
    name: "Detail Asesi on Assessments",
    component: AsesiUnderAssessors,
    exact: true
  },

  {
    path: "/assessments/:assessment_id/detail-asesi",
    name: "Detail Asesi on Assessments",
    component: AsesiUnderAssessment,
    exact: true
  },
  {
    path: "/assessments/:assessment_id/letters/:assessment_letter_id",
    name: `${multiLanguage.Edit} No.Surat`,
    component: NoSurat,
    exact: true
  },
  {
    path: "/assessments/:assessment_id/plenos/:run",
    name: `${multiLanguage.create} List Pleno`,
    component: AssignPleno,
    exact: true
  },
  {
    path: "/assessments/submission",
    exact: true,
    name: "Submissions",
    component: Submissions,
  },
  {
    path: "/assessments/rejected",
    exact: true,
    name: `${multiLanguage.list} ${multiLanguage.Assessment} ${multiLanguage.reject}`,
    component: Rejected,
  },
  {
    path: "/assessments/:assessment_id/portfolio",
    name: "Portfolio Assessment",
    component: PortfolioRoleAsesi,
    exact: true
  },
  {
    path: "/assessments/archives",
    exact: true,
    name: `${multiLanguage.archives} ${multiLanguage.schedule}`,
    component: Archive,
  },
  {
    path: "/applicants/:applicant_id/persyaratan-umum",
    name: "Persyaratan Umum Asesi",
    component: PersyaratanUmum,
    exact: true
  },

  //menu TUK
  { path: "/tuk", exact: true, name: "TUK", component: TUK },
  {
    path: "/tuk/add-tuk",
    name: `${multiLanguage.add} Data TUK`,
    component: InputData_tuk,
    exact: true
  },
  {
    path: "/tuk/edit-tuk/:tuk_id",
    name: `${multiLanguage.Edit}`,
    component: EditTuk,
    exact: true
  },

  //alumni
  {
    path: "/alumnis",
    exact: true,
    name: "Alumni",
    component: Alumni,
  },
  {
    path: "/alumnis/add-alumni",
    component: InputAlumni,
    name: "Tambah Data Alumni",
    exact: true
  },
  {
    path: "/alumnis/edit-alumni/:alumni_id",
    component: EditAlumni,
    name: "Edit Data Alumni",
    exact: true
  },
  {
    path: "/alumnis/certificate-competence/:alumni_id",
    component: CertificateAlumni,
    name: "Certificate Alumni",
    exact: true
  },

  {
    path: "/logbook/edit-logbook/:alumni_id",
    component: EditLogBook,
    name: "Edit Data LogBook",
    exact: true
  },

  //Setting
  {
    path: "/vehicles/add-vehicles",
    name: `${multiLanguage.add} Data Master Alat Berat`,
    component: InputData_Vehicles,
    exact: true
  },

  {
    path: "/vehicles/edit-vehicles/:vehicle_id",
    name: `${multiLanguage.Edit} Data Master Alat Berat`,
    component: EditData_Vehicles,
    exact: true
  },
  {
    path: "/institute/edit-institute/:institution_id",
    name: `${multiLanguage.add} Data Master Institusi`,
    component: EditInstitute,
    exact: true
  },
  {
    path: "/institute/add-institute",
    name: `${multiLanguage.add} Data Master Institusi`,
    component: AddInstitute,
    exact: true
  },
  {
    path: "/institute",
    name: `${multiLanguage.add} Data Master Institusi`,
    component: Institute,
    exact: true
  },

  //Grading
  {
    path: "/grading",
    component: GradingAcesor,
    exact: true,
  },
  {
    path: "/add/grading",
    component: AddGradingAcesor,
    exact: true,
  },
  {
    path: "/edit/grading/:accessor_competence_id",
    component: EditGradingAcesor,
    exact: true,
  },

  //management surat
  {
    path: "/management-letters",
    component: ManagementSurat,
    name: multiLanguage.mainSchema,
    exact: true,
  },

  // etc
  // {
  //   path: "/",
  //   exact: true,
  //   name: multiLanguage.home,
  //   component: DefaultLayout,
  // },
  { path: "/", component: Dashboard, exact: true },
  { path: "/dashboard", component: Dashboard, exact: true },
  { path: "/404", component: NotFound, exact: true },
  { path: "/articles/:article_id", component: News, exact: true },
  {
    path: "/message",
    name: multiLanguage.notif,
    component: Notif,
    exact: true
  },

  //master data
  {
    path: "/portfolios",
    exact: true,
    name: "Master Data",
    component: Portfolios,
  },
  {
    path: "/portfolios-umum",
    exact: true,
    name: "Master Data",
    component: Portfolios_Umum,
  },
  {
    path: "/vehicles",
    exact: true,
    name: "Master Data Alat Berat",
    component: Master_Alat_Berat,
  },
  {
    path: "/materi-uji",
    exact: true,
    name: "Master Data",
    component: Materi_Uji,
  },
  {
    path: "/portfolios/input",
    name: `${multiLanguage.add} data Portofolio`,
    component: InputData_Portfolios,
    exact: true
  },
  {
    path: "/portfolios-umum/input",
    name: `${multiLanguage.add} data Portofolio`,
    component: Input_Portfolios_umum,
    exact: true
  },
  {
    path: "/materi-uji/input",
    name: `${multiLanguage.add} data Materi Uji`,
    component: Input_Materi_Uji,
    exact: true
  },
  {
    path: "/portfolios-umum/edit-portfolios/:requirement_master_id",
    name: `${multiLanguage.Edit} Master Portofolios`,
    component: Edit_portfoliosMasterUmum,
    exact: true
  },
  {
    path: "/portfolios/edit-portfolios/:requirement_master_id",
    name: `${multiLanguage.Edit} Master Portofolios`,
    component: Edit_portfoliosMasterKhusus,
    exact: true
  },
  {
    path: "/materi-uji/edit-portfolios/:requirement_master_id",
    name: `${multiLanguage.Edit} Master Portofolios`,
    component: Edit_portfoliosMasterMateriUji,
    exact: true
  },
  // {
  //   path: "/portfolios/edit-portfolios/:master_portfolio_id",
  //   name: `${multiLanguage.Edit} Master Portofolios`,
  //   component: EditData_portfoliosMaster,
  //   exact: true
  // },
  //Restore Data
  {
    path: "/restore-data",
    exact: true,
    name: "Restore Data",
    component: RestoreData,
  },
  // Log Book
  {
    path: "/log-book",
    exact: true,
    name: "Log Book",
    component: LogBook,
  },
  {
    path: "/log-book/add-logbook/:sub_schema_id/:alumni_id",
    name: `${multiLanguage.add} Data`,
    component: InputLogBook,
    exact: true
  },
  {
    path: "/log-book/edit-logbook/:sub_schema_id/:alumni_id/:log_book_id",
    name: `${multiLanguage.add} Data`,
    component: EditLogBookAsesi,
    exact: true
  },
  // {
  //   path: "/certificate",
  //   exact: true,
  //   name: "E-Certifikat Kompetensi",
  //   component: Certificate,
  // },
  {
    path: "/edit-profile",
    exact: true,
    name: `Edit Profile`,
    component: EditProfile,
  },
];

export default routes;
