import React, { Component } from "react";
import { Label } from "reactstrap";


class LabelRequired extends Component {
  render() {
    const { label, fors, tooltip } = this.props;
    return (
      <div style={{ marginTop: "6px" }}>
        <Label for={fors}>
          <span className="required">* </span>
          {label}
          {tooltip ? tooltip : ''}
        </Label>
      </div>
    );
  }
}

export default LabelRequired;
