import ActionType from "./globalActionType";

const globalState = {
  updateState: 0,
};

// Reducer
const rootReducer = (state = globalState, action) => {
  if ((action.type === ActionType.UPDATE_STATE)) {
    return {
      ...state,
      updateState: state.updateState + 1,
    };
  }
  return state;
};

export default rootReducer;
