import React, { Component } from "react";
import { PostData } from "../../services/PostData";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Alert,
  Form,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Input } from "antd";

import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import "../../css/Login.css";
import "../../css/Button.css";
import {
  Brand_LSP,
  baseUrl,
  path_forgotPass,
} from "../../components/config/config";

import LogoConfirm from "../../assets/img/brand/confirm.png";
import "antd/dist/antd.css";

class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: "",
      hidden: true,
      modal: false,
      emailBody: "",
    };
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    const dataLocalStorage = localStorage.getItem("email");
    console.log("datalocal", dataLocalStorage);
    this.setState({
      emailBody: dataLocalStorage,
    });
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggleNested = (event) => {
    event.preventDefault();
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: false,
    });

    var data = new FormData();
    data.append("email", this.state.email);
    const options = {
      method: "POST",
      url: baseUrl + path_forgotPass,
      data: data,
    };
    axios(options).then((response) => {
      console.log(response);
      return response;
    });
  };

  toggleAll = () => {
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: true,
    });
  };

  render() {
    if (localStorage.getItem("logged_in") || this.state.logged_in) {
      return <Redirect to={"/"} />;
    }

    console.log("email body", this.state.emailBody);
    return (
      <LoadingOverlay active={this.state.loading} spinner text="Please Wait...">
        <div className="app flex-row align-items-center animated fadeIn backgroundColor">
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggle}>
              <b>Masukan Kembali Email Anda</b>
            </ModalHeader>
            <ModalBody>
              <Row className="center-block" style={{ width: "75%" }}>
                <Col md="20">
                  <Input
                    className="inputBox"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Please input your email account"
                    onChange={this.handleChange}
                  />
                </Col>
                <br />
              </Row>
              <Row className="center-block">
                <Col>
                  <Button className="btn-submit" onClick={this.toggleNested}>
                    Submit
                  </Button>
                </Col>
              </Row>
              <Modal
                isOpen={this.state.nestedModal}
                toggle={this.toggleNested}
                onClosed={this.state.closeAll ? this.toggle : undefined}
              >
                <ModalHeader>Confirmation Message</ModalHeader>
                <ModalBody>
                  Your email has been send, Please Check Your email
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.toggleAll}>
                    OK
                  </Button>
                </ModalFooter>
              </Modal>
            </ModalBody>
          </Modal>

          <Container>
            <Row className="justify-content-center">
              <Col md="8">
                <Card
                  className="p-4"
                  style={{ width: "65%", margin: "auto", height: "600px" }}
                >
                  <CardBody>
                    <Row className="logo">
                      <Col>
                        <h1
                          style={{
                            textAlign: "center",
                            marginTop: "10px",
                            paddingTop: "20px",
                            color: "#aaa",
                          }}
                        >
                          Pendaftaran Berhasil!
                        </h1>
                      </Col>
                    </Row>
                    <Row className="logo" style={{ marginTop: '20px' }}>
                      <Col>
                        <img className="login" src={LogoConfirm} alt="" />
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col>
                        <h3>Cek Email Anda!</h3>
                      </Col>
                    </Row> */}
                    <Row style={{ marginTop: '20px' }}>
                      <Col>
                        <p>
                          Silahkan login menggunakan password 123456
                        </p>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col>
                        <Button
                          className="btn-signUp"
                          type="submit"
                          onClick={this.toggle}
                        >
                          Kirim Ulang
                        </Button>
                      </Col>
                    </Row> */}
                    <Row style={{ marginTop: "35px" }} >
                      <Col>
                        <Link to={"/login"}>
                          <Button className="btn-login" type="submit">
                            Login
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </LoadingOverlay>
    );
  }
}

export default Confirm;
