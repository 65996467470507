import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Alert,
  Label,
} from "reactstrap";
import { Select, Spin, Divider, Modal as Modal1, Tooltip } from "antd";
import {
  path_masterData,
  baseUrl,
  path_jobs,
  path_religion,
  Brand_LSP,
  path_login,
  path_province,
  path_city,
  path_district,
  path_village,
  path_institution,
} from "../../components/config/config";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import Axios from "axios";
import "../../css/loaderComponent.css";
import "antd/dist/antd.css";
import LoadingOverlay from "react-loading-overlay";
import { multiLanguage } from "../../components/Language/getBahasa";
import LabelRequired from "../../components/Label/LabelRequired";
import SignatureCanvas from "react-signature-canvas";
import ButtonBack from "../../components/Button/ButtonBack";
import { PostRegister, PostRegisterAsesi } from "../../services/PostData";
import LabelPlaceholder from "../../components/Label/PlaceholderLabelAlamat";
import { UploadPicture } from "../../components/Button/ButtonUploadPicture";

const { Option } = Select;

class RegistrasiAsesi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        form_name: "",
        form_type: "",
      },
      payload: [],
      payloadProvince: [],
      payloadCity: [],
      payloadDistrict: [],
      payloadVillage: [],
      payloadOfficeProvince: [],
      payloadOfficeCity: [],
      payloadOfficeDistrict: [],
      payloadOfficeVillage: [],
      payloadReligion: [],
      payloadTuk: [],
      modalSignature: false,
      fetching: false,
      signature: "",
      uploadTTD: [],
      hidden: true,
      payloadJobs: [],
      payloadReligion: [],
      username: "",
      email: "",
      contact: "",
      first_name: "",
      last_name: "",
      place_of_birth: "",
      date_of_birth: "",
      gender_code: "",
      pendidikan_terakhir: "",
      jobs_code: "",
      address: "",
      nik: "",
      institution_id: "",
      tuk_id: "",
      telepon_pekerjaan: "",
      kode_pos_pekerjaan: "",
      fax_pekerjaan: "",
      email_pekerjaan: "",
      alamat_pekerjaan: "",
      signature: "",
      religion: "",
      province_id: "",
      kota_id: "",
      kecamatan_id: "",
      kelurahan_id: "",
      province_office: "",
      kota_office: "",
      kecamatan_office: "",
      kelurahan_office: "",
      category_user: "",
      payloadInstitution: [],
      symbolsArr: ["e", "E", "+", "-", "."],
      searchTuk:'',
      prevInterval:'',
      intervalPass:false,
      kelurahan_id:'',
      toolFirst: false,
      toolLast: false,
    };
    this.prevTimeOut = '';
  }

  confTimeOut = () => {
    this.setState({ intervalPass:false }, () => {
      this.prevTimeOut = setTimeout(() => this.setIntervalPass(), 1200);
    });
  }

  setIntervalPass = () => {
    this.setState({ intervalPass:true });
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.searchTuk!=prevState.searchTuk){
      if(this.prevTimeOut){
        clearTimeout(this.prevTimeOut);
        this.confTimeOut();
      }else{
        this.confTimeOut();
      }
    }
    if(this.state.intervalPass!=prevState.intervalPass){
      if(this.state.intervalPass){
        this.getSearchTuk();
      }
    }
  }

  getSearchTuk = () => {
    const { searchTuk } = this.state

    this.setState({
      fetching: true,
    });

    let optionsVillage =  {
      url: `${baseUrl}/public${path_institution}?limit=unlimited`,
      data: null,
    };

    if (searchTuk && searchTuk.length > 2) {
      optionsVillage = {
        url: `${baseUrl}/public${path_institution}?limit=unlimited&search=${searchTuk}`,
        data: null,
      };
      
    }

    Axios(optionsVillage).then((response) => {
      this.setState({
        payloadInstitution: response.data.data,
        fetching: false,
      });
    });
  }

  handleChange = (event, errors, values) => {
    this.setState({
      [event.target.name]: event.target.value,
      errors,
      values,
    });
  };
  handleCategory = (value) => {
    if (value == "AHEMCE") {
      this.setState({
        category_user: value,
        is_ahemce: 1,
      });
    } else {
      this.setState({
        category_user: value,
        is_ahemce: 0,
      });
    }
  };

  handleFocus = (type) => {
    if(type === "first"){
      this.setState({ toolFirst:true })
    }else{
      this.setState({ toolLast:true })
    }
  }

  handleBlur = (type) => {
    if(type === "first"){
      this.setState({ toolFirst:false })
    }else{
      this.setState({ toolLast:false })
    }
  }

  handleUsernameEmail = (input) => (event) => {
    if (input === "username") {
      this.setState({ [input]: event.target.value });
      this.Get_Username(event.target.value);
    } else {
      this.setState({ [input]: event.target.value });
      this.Get_Emails(event.target.value);
    }
  };

  Get_Username = (value) => {
    this.setState({
      loading: true,
    });
    const path = `/public/users_validation?username=${value}`;
    Axios(baseUrl + path)
      .then((response) => {
        this.setState({
          loading: false,
          hidden: true,
          message: "",
        });
      })
      .catch((error) => {
        this.setState({
          response: error.response.data.error.code,
        });

        switch (this.state.response) {
          case 400:
            this.setState({
              hidden: false,
              message: "Username sudah terdaftar",
              loading: false,
            });
            break;

          default:
            break;
        }
      });
  };
  Get_Emails = (value) => {
    this.setState({
      loading: true,
    });
    const path = `/public/users_validation?email=${value}`;
    Axios(baseUrl + path)
      .then((response) => {
        this.setState({
          loading: false,
          hidden: true,
          message: "",
        });
      })
      .catch((error) => {
        this.setState({
          response: error.response.data.error.code,
        });

        switch (this.state.response) {
          case 400:
            this.setState({
              hidden: false,
              message: "Email sudah terdaftar",
              loading: false,
            });
            break;

          default:
            break;
        }
      });
  };

  toggleSignature = () => {
    this.setState({
      modalSignature: !this.state.modalSignature,
    });
  };

  changeSignature = () => {
    this.setState({
      signature: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
      modalSignature: !this.state.modalSignature,
    });
  };
  onChange = (event) => {
    if (event.target.checked === true) {
      this.setState({
        multiple: "1",
      });
    } else {
      this.setState({
        multiple: "0",
      });
    }
  };
  Get(options, response) {
    Axios(options)
      .then((res) => {
        this.setState({
          [response]: res.data.data,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace("/login");
        } else if (error.response.status === 419) {
          this.errorTrial();
        }
      });
  }

  handleBack = () => {
    Modal1.confirm({
      content: "Apakah anda yakin akan ke halaman login ?",
      onOk() {
        localStorage.clear();
        window.location.replace("/login");
      },
    });
  };

  onChangeInstitution = (value) => {
    // const data = this.state.payloadInstitution.filter((item) => {
    //   return item.institution_id === value;
    // });
    this.setState({
      institution_id: value,
    });
  };
  onSearchInstitution = (value) => {
    this.setState({
      searchTuk: value,
    });
  };
  componentDidMount() {
    const optionInstitution = {
      url: `${baseUrl}/public/${path_institution}?limit=unlimited`,
      data: null,
    };
    const optionsTuk = {
      url: `${baseUrl}/public/tuks?limit=unlimited`,
      data: null,
    };
    const optionsJobs = {
      url: `${baseUrl}/public/${path_jobs}?limit=100`,
      data: null,
    };
    const optionsReligion = {
      url: `${baseUrl}/public/${path_religion}?limit=100`,
      data: null,
    };
    const optionsProvince = {
      url: `${baseUrl}/public/${path_province}?limit=100`,
      data: null,
    };
    this.Get(optionInstitution, "payloadInstitution");
    this.Get(optionsTuk, "payloadTuk");
    this.Get(optionsJobs, "payloadJobs");
    this.Get(optionsReligion, "payloadReligion");
    this.Get(optionsProvince, "payloadProvince");
    this.Get(optionsProvince, "payloadOfficeProvince");
  }
  onChangeProvince = (input) => (value) => {
    this.setState({
      province_id: value.key,
    });
    const optionsCity = {
      url: `${baseUrl}/public${path_city}?limit=100&province_id=${
        value.key == undefined ? "" : value.key
      }`,
      data: null,
    };

    this.Get(optionsCity, "payloadCity");
  };

  onSearch = (value) => {
    this.setState({
      fetching: true,
    });

    if (value !== "") {
      const optionsProvince = {
        url: `${baseUrl}/public/${path_province}?limit=100&search=${value}`,
        data: null,
      };
      Axios(optionsProvince).then((response) => {
        this.setState({
          payloadProvince: response.data.data,
          fetching: false,
        });
      });
    } else {
      this.setState({
        payloadProvince: [],
        fetching: false,
      });
    }
  };

  onChangeCity = (input) => (value) => {
    this.setState({
      kota_id: value.key,
    });

    const optionsDistrict = {
      url: `${baseUrl}/public${path_district}?limit=100&kota_id=${
        value.key == undefined ? "" : value.key
      }`,
      data: null,
    };

    this.Get(optionsDistrict, "payloadDistrict");
  };
  onSearchCity = (value) => {
    this.setState({
      fetching: true,
    });

    if (value !== "") {
      const optionsCity = {
        url: `${baseUrl}/public${path_city}?limit=100&province_id=${this.state.province_id}&search=${value}`,
        data: null,
      };
      Axios(optionsCity).then((response) => {
        this.setState({
          payloadCity: response.data.data,
          fetching: false,
        });
      });
    } else {
      this.setState({
        payloadCity: [],
        fetching: false,
      });
    }
  };

  onChangeDistrict = (input) => (value) => {
    this.setState({
      kecamatan_id: value.key,
    });

    const optionsVillage = {
      url: `${baseUrl}/public${path_village}?limit=100&kecamatan_id=${
        value.key == undefined ? "" : value.key
      }`,
      data: null,
    };

    this.Get(optionsVillage, "payloadVillage");
  };

  onChangeVillage = (input) => (value) => {
    this.setState({
      kelurahan_id: value.key,
    });
  };

  uploadProps = (upload) => {
    this.setState({
      signature: upload.base64,
      modalSignature: !this.state.modalSignature,
    });
  };

  onSearchDistrict = (value) => {
    this.setState({
      fetching: true,
    });

    if (value !== "") {
      const optionsDistrict = {
        url: `${baseUrl}/public${path_district}?limit=100&kota_id=${this.state.kota_id}&search=${value}`,
        data: null,
      };
      Axios(optionsDistrict).then((response) => {
        this.setState({
          payloadDistrict: response.data.data,
          fetching: false,
        });
      });
    } else {
      this.setState({
        payloadDistrict: [],
        fetching: false,
      });
    }
  };

  onSearchVillage = (value) => {
    this.setState({
      fetching: true,
    });

    if (value !== "") {
      const optionsVillage = {
        url: `${baseUrl}/public${path_village}?limit=100&kecamatan_id=${this.state.kecamatan_id}&search=${value}`,
        data: null,
      };
      Axios(optionsVillage).then((response) => {
        this.setState({
          payloadVillage: response.data.data,
          fetching: false,
        });
      });
    } else {
      this.setState({
        payloadVillage: [],
        fetching: false,
      });
    }
  };
  // onChangeOfficeProvince = (input) => (value) => {
  //   this.setState({
  //     province_office: value.key,
  //   });
  //   const optionsCity = {
  //     url: `${baseUrl}/public${path_city}?limit=100&province_id=${
  //       value.key == undefined ? "" : value.key
  //     }&limit=unlimited`,
  //     data: null,
  //   };

  //   this.Get(optionsCity, "payloadOfficeCity");
  // };

  // onOfficeSearch = (value) => {
  //   this.setState({
  //     fetching: true,
  //   });

  //   if (value !== "") {
  //     const optionsProvince = {
  //       url: `${baseUrl}/public/${path_province}?limit=100&search=${value}`,
  //       data: null,
  //     };
  //     Axios(optionsProvince).then((response) => {
  //       this.setState({
  //         payloadOfficeProvince: response.data.data,
  //         fetching: false,
  //       });
  //     });
  //   } else {
  //     this.setState({
  //       payloadOfficeProvince: [],
  //       fetching: false,
  //     });
  //   }
  // };

  toggle = () => {
    this.setState({
      modalUpload: !this.state.modalUpload,
    });
  };

  // onChangeOfficeCity = (input) => (value) => {
  //   this.setState({
  //     kota_office: value.key,
  //   });

  //   const optionsDistrict = {
  //     url: `${baseUrl}/public${path_district}?limit=100&kota_id=${
  //       value.key == undefined ? "" : value.key
  //     }&limit=unlimited`,
  //     data: null,
  //   };

  //   this.Get(optionsDistrict, "payloadOfficeDistrict");
  // };

  // onOfficeSearchCity = (value) => {
  //   this.setState({
  //     fetching: true,
  //   });

  //   if (value !== "") {
  //     const optionsCity = {
  //       url: `${baseUrl}/public${path_city}?limit=100&province_id=${this.state.province_office}&search=${value}`,
  //       data: null,
  //     };
  //     Axios(optionsCity).then((response) => {
  //       this.setState({
  //         payloadOfficeCity: response.data.data,
  //         fetching: false,
  //       });
  //     });
  //   } else {
  //     this.setState({
  //       payloadOfficeCity: [],
  //       fetching: false,
  //     });
  //   }
  // };
  // onChangeOfficeDistrict = (input) => (value) => {
  //   this.setState({
  //     kecamatan_office: value.key,
  //   });

  //   const optionsVillage = {
  //     url: `${baseUrl}/public${path_village}?limit=100&kecamatan_id=${
  //       value.key == undefined ? "" : value.key
  //     }&limit=unlimited`,
  //     data: null,
  //   };

  //   this.Get(optionsVillage, "payloadOfficeVillage");
  // };

  // onOfficeSearchDistrict = (value) => {
  //   this.setState({
  //     fetching: true,
  //   });

  //   if (value !== "") {
  //     const optionsDistrict = {
  //       url: `${baseUrl}/public${path_district}?limit=100&kota_id=${this.state.kota_office}&search=${value}`,
  //       data: null,
  //     };
  //     Axios(optionsDistrict).then((response) => {
  //       this.setState({
  //         payloadOfficeDistrict: response.data.data,
  //         fetching: false,
  //       });
  //     });
  //   } else {
  //     this.setState({
  //       payloadOfficeDistrict: [],
  //       fetching: false,
  //     });
  //   }
  // };
  // onChangeOfficeVillage = (input) => (value) => {
  //   this.setState({
  //     kelurahan_office: value.key,
  //   });
  // };
  // onOfficeSearchVillage = (value) => {
  //   this.setState({
  //     fetching: true,
  //   });

  //   if (value !== "") {
  //     const optionsVillage = {
  //       url: `${baseUrl}/public${path_village}?limit=100&kecamatan_id=${this.state.kecamatan_office}&search=${value}`,
  //       data: null,
  //     };
  //     Axios(optionsVillage).then((response) => {
  //       this.setState({
  //         payloadOfficeVillage: response.data.data,
  //         fetching: false,
  //       });
  //     });
  //   } else {
  //     this.setState({
  //       payloadOfficeVillage: [],
  //       fetching: false,
  //     });
  //   }
  // };

  handleValidation = () => {
    const emailReg = /(.+)@(.+){2,}\.(.+){2,}/;

    const usernameReg = /^\S*$/;

    if (
      this.state.username === "" ||
      this.state.first_name === "" ||
      this.state.gender_code === "" ||
      this.state.institution_id === "" ||
      this.state.tuk_id === "" ||
      this.state.email === "" ||
      this.state.jobs_code === "" ||
      this.state.religion === "" ||
      this.state.nik === "" ||
      this.state.signature === "" ||
      this.state.contact === "" ||
      this.state.place_of_birth === "" ||
      this.state.date_of_birth === "" ||
      this.state.address === "" ||
      this.state.pendidikan_terakhir === "" ||
      this.state.telepon_pekerjaan === "" ||
      this.state.kode_pos_pekerjaan === "" ||
      this.state.email_pekerjaan == ""
    ) {
      this.setState({
        hidden: false,
        message: multiLanguage.alertInput,
        loading: false,
      });
    } else if (!usernameReg.test(this.state.username)) {
      this.setState({
        hidden: false,
        message: "Masih ada spasi dalam kolom username",
      });
    } else if (
      !emailReg.test(this.state.email) ||
      !emailReg.test(this.state.email_pekerjaan)
    ) {
      this.setState({
        hidden: false,
        message: "Format Email tidak sesuai",
      });
    } else if (
      this.state.contact.length < 6 ||
      this.state.contact.length > 14
    ) {
      this.setState({
        hidden: false,
        message: "Nomor telepon minimal 6 karakter dan maksimal 14 karakter",
        loading: false,
      });
    } else if (!this.state.nik.length === 16 || this.state.nik.length > 16) {
      this.setState({
        hidden: false,
        message: "Nik harus 16 digit",
        loading: false,
      });
    } else if (this.state.province_id == "") {
      this.setState({
        hidden: false,
        message: "Propinsi di KTP harus diisi ",
        loading: false,
      });
    } else if (this.state.kota_id == "") {
      this.setState({
        hidden: false,
        message: "Kota di KTP harus diisi ",
        loading: false,
      });
    } else if (this.state.kecamatan_id == "") {
      this.setState({
        hidden: false,
        message: "Kecamatan di KTP harus diisi ",
        loading: false,
      });
    } else if (this.state.kelurahan_id == "") {
      this.setState({
        hidden: false,
        message: "Kelurahan di KTP harus diisi ",
        loading: false,
      });
    }
    // else if (this.state.province_office == "") {
    //   this.setState({
    //     hidden: false,
    //     message: "Propinsi di Kantor harus diisi ",
    //     loading: false,
    //   });
    // } else if (this.state.kota_office == "") {
    //   this.setState({
    //     hidden: false,
    //     message: "Kota di Kantor harus diisi ",
    //     loading: false,
    //   });
    // } else if (this.state.kecamatan_office == "") {
    //   this.setState({
    //     hidden: false,
    //     message: "Kecamatan di Kantor harus diisi ",
    //     loading: false,
    //   });
    // } else if (this.state.kelurahan_office == "") {
    //   this.setState({
    //     hidden: false,
    //     message: "Kelurahan di Kantor harus diisi ",
    //     loading: false,
    //   });
    // }
    else {
      this.handleSubmit();
    }
  };
  handleChangeRoleStateASESI = (value) => {
    this.setState({
      apl_document_state: value,
    });
  };
  onChangeTuk = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleSubmit = (event) => {
    this.setState({ fireRedirect: true });

    this.setState({
      loading: true,
    });
    const dataCategory = {
      category_user:
        this.state.category_user == ""
          ? "NON AHEMCE"
          : this.state.category_user,
    };
    PostRegisterAsesi("/public/users/applicants", {
      username: this.state.username,
      email: this.state.email,
      contact: this.state.contact,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      gender_code: this.state.gender_code,
      place_of_birth: this.state.place_of_birth,
      date_of_birth: this.state.date_of_birth,
      institution_id: this.state.institution_id,
      nik: this.state.nik,
      tuk_id: this.state.tuk_id,
      address: this.state.address,
      pendidikan_terakhir: this.state.pendidikan_terakhir,
      jobs_code: this.state.jobs_code,
      telepon_pekerjaan: this.state.telepon_pekerjaan,
      kode_pos_pekerjaan: this.state.kode_pos_pekerjaan,
      fax_pekerjaan: this.state.fax_pekerjaan,
      email_pekerjaan: this.state.email_pekerjaan,
      alamat_pekerjaan: this.state.alamat_pekerjaan,
      religion: this.state.religion,
      signature: this.state.signature,
      kelurahan_id: this.state.kelurahan_id,
      instansi_kel_id: this.state.kelurahan_office,
      category_user: dataCategory.category_user,
    })
      .then((res) => {
        localStorage.setItem("email", this.state.email);
        this.setState({
          loading: false,
        });

        window.location.replace("/confirm");

        return;
      })
      .catch((error) => {
        // console.log(error);
        this.setState({
          loading: false,
        });
        this.setState({
          response: error.data.error.code,
        });
        switch (this.state.response) {
          case 400:
            this.setState({
              hidden: false,
              message: error.data.error.message,
              loading: false,
            });
            break;

          case 409:
            this.setState({
              hidden: false,
              message: multiLanguage.alertAlready,
              loading: false,
            });
            break;

          default:
            break;
        }
      });
  };

  render() {
    const {
      payloadTuk,
      payloadJobs,
      payloadProvince,
      payloadDistrict,
      payloadCity,
      payloadVillage,
      province_id,
      kota_id,
      kecamatan_id,
      kelurahan_id,
      // payloadOfficeProvince,
      // payloadOfficeDistrict,
      // payloadOfficeCity,
      // payloadOfficeVillage,
      // province_office,
      // kota_office,
      // kecamatan_office,
      // kelurahan_office,
      fetching,
      payloadInstitution,
    } = this.state;

    const { Logo } = Brand_LSP("demo");
    const now = new Date(new Date().setFullYear(new Date().getFullYear() - 17))
      .toJSON()
      .split("T")[0];
    let sevenYearsAgo = new Date();
    sevenYearsAgo.setFullYear(sevenYearsAgo.getFullYear() - 200);
    sevenYearsAgo = sevenYearsAgo.toJSON().split("T")[0];

    return (
      <LoadingOverlay active={this.state.loading} spinner text="Loading..">
        <AvForm
          action=""
          encType="multipart/form-data"
          className="form-horizontal"
          style={{ marginTop: "50px" }}
        >
          {/* <AvGroup row>
            <Col md="12">
              <img
                src={Logo}
                style={{
                  width: 200,

                  float: "right",

                  marginRight: "100px",
                  marginTop: "15px",
                }}
              />
            </Col>
          </AvGroup> */}

          <AvGroup row>
            <Col sm={{ size: "3", offset: 1 }}>
              <LabelRequired fors="form_name" label={"Nama Pengguna"} />
              <AvField
                type="text"
                id="username"
                name="username"
                placeholder={"Username"}
                value={this.state.username}
                onChange={this.handleUsernameEmail("username")}
                validate={{
                  required: {
                    value: true,
                    errorMessage: multiLanguage.alertName,
                  },
                }}
              />
            </Col>
            <Col md="3">
              <LabelRequired fors="email" label={"Email"} />

              <AvField
                type="text"
                id="email"
                name="email"
                value={this.state.email}
                placeholder={"Email"}
                onChange={this.handleUsernameEmail("email")}
                validate={{
                  required: {
                    value: true,
                    errorMessage: multiLanguage.alertName,
                  },
                }}
              />
            </Col>
            <Col md="3">
              <LabelRequired fors="contact" label={"Nomor Telepon"} />

              <AvField
                type="text"
                id="contact"
                name="contact"
                maxLength="13"
                onChange={this.handleChange}
                placeholder={"Nomor Telepon"}
                defaultValue={this.state.contact}
                onKeyDown={(e) =>
                  this.state.symbolsArr.includes(e.key) && e.preventDefault()
                }
                pattern="^[0-9]*$"
                required
              />
              <AvFeedback>{multiLanguage.alertField}</AvFeedback>
            </Col>
          </AvGroup>
          <AvGroup row>
            <Col sm={{ size: "3", offset: 1 }}>
              <LabelRequired
                fors="first_name"
                label={"Nama Depan"}
                tooltip={<Tooltip visible={this.state.toolFirst} title="Nama sesuai KTP"><i onMouseLeave={() => this.handleBlur('first')} onMouseEnter={() => this.handleFocus('first')} className="fa fa-question-circle"></i></Tooltip>}
              />

              <AvField
                onFocus={() => this.handleFocus('first')}
                onBlur={() => this.handleBlur('first')}
                type="text"
                id="first_name"
                name="first_name"
                value={this.state.first_name}
                placeholder={"Nama sesuai KTP"}
                onChange={this.handleChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: multiLanguage.alertName,
                  },
                }}
              />
            </Col>
            <Col md="3">
              {/* <LabelRequired fors="last_name" label={"Nama Belakang"} /> */}
              <Label style={{ marginTop: 5 }}>Nama Belakang <Tooltip visible={this.state.toolLast} title="Abaikan jika tidak ada nama belakang"><i onMouseLeave={() => this.handleBlur('last')} onMouseEnter={() => this.handleFocus('last')} className="fa fa-question-circle"></i></Tooltip></Label>

              <AvInput
                onFocus={() => this.handleFocus('last')}
                onBlur={() => this.handleBlur('last')}
                type="text"
                id="last_name"
                name="last_name"
                value={this.state.last_name}
                placeholder={"Abaikan jika tidak ada nama belakang"}
                onChange={this.handleChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: multiLanguage.alertName,
                  },
                }}
              />
            </Col>
            <Col md="3">
              <LabelRequired fors="gender_code" label={multiLanguage.gender} />

              <AvRadioGroup
                inline
                name="gender_code"
                style={{ marginLeft: 20 }}
                value={this.state.gender_code}
                // defaultValue={values.gender_code}
                errorMessage={multiLanguage.alertRadioButton}
              >
                <AvRadio
                  label={multiLanguage.male}
                  value="M"
                  onChange={this.handleChange}
                />
                <AvRadio
                  label={multiLanguage.female}
                  value="F"
                  onChange={this.handleChange}
                />
              </AvRadioGroup>
            </Col>
          </AvGroup>
          <AvGroup row>
            <Col sm={{ size: "3", offset: 1 }}>
              <LabelRequired fors="place_of_birth" label={"Tempat Lahir"} />

              <AvInput
                type="text"
                id="place_of_birth"
                name="place_of_birth"
                onChange={this.handleChange}
                value={this.state.place_of_birth}
                validate={{
                  required: {
                    value: true,
                    errorMessage: multiLanguage.alertName,
                  },
                }}
              />
            </Col>
            <Col md="3">
              <LabelRequired fors="date_of_birth" label={"Tanggal Lahir"} />

              <AvInput
                type="date"
                id="date_of_birth"
                name="date_of_birth"
                min={sevenYearsAgo}
                max={now}
                onChange={this.handleChange}
                value={this.state.date_of_birth}
                validate={{
                  required: {
                    value: true,
                    errorMessage: multiLanguage.alertName,
                  },
                }}
              />
            </Col>

            <Col md="3">
              <LabelRequired fors="nik" label={"NIK"} />

              <AvField
                type="text"
                id="nik"
                name="nik"
                maxLength="16"
                onChange={this.handleChange}
                value={this.state.nik}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onKeyDown={(e) =>
                  this.state.symbolsArr.includes(e.key) && e.preventDefault()
                }
                validate={{
                  required: {
                    value: true,
                    errorMessage: multiLanguage.alertName,
                  },
                  maxLength: {
                    value: 16,
                    errorMessage: "Nik anda melebihi 16 digit",
                  },
                }}
              />
            </Col>
          </AvGroup>
          <AvGroup row>
            <Col sm={{ size: "3", offset: 1 }}>
              <LabelRequired fors="religion" label={multiLanguage.religion} />

              <AvField
                type="select"
                name="religion"
                value={this.state.religion}
                onChange={this.handleChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: multiLanguage.alertName,
                  },
                }}
              >
                <option>{"Pilih Agama"}</option>
                {this.state.payloadReligion.map((d) => {
                  return (
                    <option value={d.row_id} key={d.religion}>
                      {d.religion}
                    </option>
                  );
                })}
              </AvField>
            </Col>
            <Col md="3">
              <LabelRequired fors="jobs_code" label={multiLanguage.jobs} />

              <AvField
                type="select"
                name="jobs_code"
                value={this.state.jobs_code}
                onChange={this.handleChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: multiLanguage.alertName,
                  },
                }}
              >
                <option>{"Pilih Pekerjaan"}</option>
                {payloadJobs.map(({ jobs_code, jobs_name }) => {
                  return (
                    <option value={jobs_code} key={jobs_code}>
                      {jobs_name}
                    </option>
                  );
                })}
              </AvField>
            </Col>
            <Col md="3">
              <LabelRequired
                fors="pendidikan_terakhir"
                label={multiLanguage.lastEducation}
              />

              <AvField
                type="select"
                id="pendidikan_terakhir"
                name="pendidikan_terakhir"
                value={this.state.pendidikan_terakhir}
                onChange={this.handleChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: multiLanguage.alertName,
                  },
                }}
              >
                <option value="">{multiLanguage.select}</option>
                <option value="SD">SD</option>
                <option value="SMP">SMP</option>
                <option value="SMA/Sederajat">SMA/Sederajat</option>
                <option value="D1">D1</option>
                <option value="D2">D2</option>
                <option value="D3">D3</option>
                <option value="D4">D4</option>
                <option value="S1">S1</option>
                <option value="S2">S2</option>
                <option value="S3">S3</option>
              </AvField>
            </Col>
          </AvGroup>
          <Divider orientation="left" style={{ color: "black" }}>
            Alamat sesuai KTP
          </Divider>
          <Row>
            <Col sm={{ size: "3", offset: 1 }}>
              <LabelRequired
                fors="province_id"
                label={multiLanguage.province}
              />

              <Select
                // value={province_id}
                showSearch
                labelInValue
                placeholder={
                  <LabelPlaceholder
                    fors="province_id"
                    label={
                      "-" +
                      multiLanguage.select +
                      " " +
                      multiLanguage.province +
                      "-"
                    }
                  />
                }
                notFoundContent={fetching ? <Spin size="small" /> : null}
                onChange={this.onChangeProvince("province_id")}
                style={{ width: "100%" }}
                onSearch={this.onSearch}
                filterOption={false}
              >
                {payloadProvince.map((d) => (
                  <Option key={d.province_id}>{d.province_name}</Option>
                ))}
              </Select>
            </Col>

            <Col md="3">
              <LabelRequired
                fors="kota_id"
                label={"Kabupaten" + "/" + multiLanguage.city}
              />

              <Select
                showSearch
                labelInValue
                onSearch={this.onSearchCity}
                filterOption={false}
                placeholder={
                  <LabelPlaceholder
                    fors="kota_id"
                    label={
                      "-" +
                      multiLanguage.select +
                      " " +
                      multiLanguage.city +
                      "-"
                    }
                  />
                }
                notFoundContent={fetching ? <Spin size="small" /> : null}
                onChange={this.onChangeCity("city_id")}
                style={{ width: "100%" }}
              >
                {payloadCity.map((d) => (
                  <Option key={d.kota_id}>{d.kota_name}</Option>
                ))}
              </Select>
            </Col>

            <Col md="3">
              <LabelRequired
                fors="kecamatan_id"
                label={multiLanguage.district}
              />

              <Select
                showSearch
                labelInValue
                placeholder={
                  <LabelPlaceholder
                    fors="kecamatan_id"
                    label={
                      "-" +
                      multiLanguage.select +
                      " " +
                      multiLanguage.district +
                      "-"
                    }
                  />
                }
                notFoundContent={fetching ? <Spin size="small" /> : null}
                onChange={this.onChangeDistrict("district_id")}
                style={{ width: "100%" }}
                onSearch={this.onSearchDistrict}
                filterOption={false}
              >
                {payloadDistrict.map((d) => (
                  <Option key={d.kecamatan_id}>{d.kecamatan_name}</Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: "3", offset: 1 }}>
              <LabelRequired
                fors="kelurahan_id"
                label={multiLanguage.village + "/" + "Desa"}
              />

              <Select
                // value={kelurahan_id}
                showSearch
                labelInValue
                onSearch={this.onSearchVillage}
                filterOption={false}
                placeholder={
                  <LabelPlaceholder
                    fors="kelurahan_id"
                    label={
                      "-" +
                      multiLanguage.select +
                      " " +
                      multiLanguage.district +
                      "-"
                    }
                  />
                }
                notFoundContent={fetching ? <Spin size="small" /> : null}
                onChange={this.onChangeVillage("village_id")}
                style={{ width: "100%" }}
              >
                {payloadVillage.map((d) => (
                  <Option key={d.kelurahan_id}>{d.kelurahan_name}</Option>
                ))}
              </Select>
            </Col>
            <Col md="3">
              <LabelRequired fors="address" label={"Alamat Rumah"} />
              <AvInput
                type="textarea"
                id="address"
                name="address"
                value={this.state.address}
                placeholder={"Alamat Rumah"}
                onChange={this.handleChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: multiLanguage.alertName,
                  },
                }}
              />
            </Col>
          </Row>
          <Divider orientation="left" style={{ color: "black" }} />
          <Row>
            <Col sm={{ size: "3", offset: 1 }}>
              <LabelRequired fors="form_name" label={multiLanguage.nameTuk} />
              <AvField
                type="select"
                name="tuk_id"
                value={this.state.tuk_id}
                onChange={this.onChangeTuk("tuk_id")}
                validate={{
                  required: {
                    value: true,
                    errorMessage: multiLanguage.alertName,
                  },
                }}
                // defaultValue={values.tuk_id}
              >
                <option value="" style={{ color: "#5c6873", opacity: 0.58 }}>
                  {multiLanguage.select} TUK
                </option>
                {payloadTuk.map(({ tuk_id, tuk_name }) => {
                  return (
                    <option value={tuk_id} key={tuk_id}>
                      {tuk_name}
                    </option>
                  );
                })}
              </AvField>
            </Col>
            <Col md="3">
              <LabelRequired
                fors="institution"
                label={multiLanguage.institute}
              />
              <Select
                showSearch
                onSearch={this.onSearchInstitution}
                placeholder={
                  <LabelPlaceholder
                    fors="institution_id"
                    label={
                      "-" +
                      multiLanguage.select +
                      " " +
                      multiLanguage.institute +
                      "-"
                    }
                  />
                }
                filterOption={false}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                style={{ width: "100%" }}
                onChange={this.onChangeInstitution}
              >
                {payloadInstitution.map((d) => (
                  <Option key={d.institution_id}>{d.institution_name}</Option>
                ))}
              </Select>
              {/* <AvField
                type="text"
                id="institution"
                name="institution"
                value={this.state.institution}
                placeholder={"Institusi"}
                onChange={this.handleChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: multiLanguage.alertName,
                  },
                }}
              /> */}
            </Col>
            <Col md="3">
              <LabelRequired fors="telepon_pekerjaan" label="Telepon Kantor" />
              <AvField
                type="text"
                onKeyDown={(e) =>
                  this.state.symbolsArr.includes(e.key) && e.preventDefault()
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength="14"
                id="telepon_pekerjaan"
                value={this.state.telepon_pekerjaan}
                name="telepon_pekerjaan"
                placeholder={"Telepon Pekerjaan"}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <AvGroup row>
            <Col sm={{ size: "3", offset: 1 }}>
              <LabelRequired
                fors="kode_pos_pekerjaan"
                label="Kode POS kantor"
              />
              <AvField
                type="text"
                id="kode_pos_pekerjaan"
                name="kode_pos_pekerjaan"
                maxLength="5"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                value={this.state.kode_pos_pekerjaan}
                placeholder={"Kode Pos Pekerjaan"}
                onChange={this.handleChange}
              />
            </Col>
            <Col md="3">
              <Label>Fax Kantor</Label>
              <AvField
                type="text"
                onKeyDown={(e) =>
                  this.state.symbolsArr.includes(e.key) && e.preventDefault()
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength="14"
                id="fax_pekerjaan"
                name="fax_pekerjaan"
                value={this.state.fax_pekerjaan}
                placeholder={"Fax Kantor"}
                onChange={this.handleChange}
              />
            </Col>
            <Col md="3">
              <LabelRequired fors="email_pekerjaan" label="Email Kantor" />
              <AvField
                type="text"
                id="email_pekerjaan"
                name="email_pekerjaan"
                value={this.state.email_pekerjaan}
                placeholder={"Email Pekerjaan"}
                onChange={this.handleChange}
              />
            </Col>
          </AvGroup>
          <Row>
            <Col sm={{ size: "3", offset: 1 }}>
              <Label htmlFor="register_number">Kategori Asesi</Label>
              <Select
                value={this.state.category_user}
                name="category_user"
                id="category_user"
                onChange={this.handleCategory}
              >
                <Option value="AHEMCE">AHEMCE</Option>
                <Option value="NON AHEMCE">NON AHEMCE</Option>
              </Select>
            </Col>
          </Row>

          {/* <Divider orientation="left" style={{ color: "black" }}>
            Alamat Kantor
          </Divider>
          <Row>
            <Col sm={{ size: "3", offset: 1 }}>
              <LabelRequired
                fors="province_office"
                label={multiLanguage.province}
              />

              <Select
                // value={province_office}
                showSearch
                labelInValue
                placeholder={
                  <Label style={{ color: "#5c6873" }}>
                    {"-" +
                      multiLanguage.select +
                      " " +
                      multiLanguage.province +
                      "-"}
                  </Label>
                }
                notFoundContent={fetching ? <Spin size="small" /> : null}
                onChange={this.onChangeOfficeProvince("province_office")}
                style={{ width: "100%" }}
                filterOption={false}
                onSearch={this.onOfficeSearch}
              >
                {payloadOfficeProvince.map((d) => (
                  <Option key={d.province_id}>{d.province_name}</Option>
                ))}
              </Select>
            </Col>

            <Col md="3">
              <LabelRequired
                fors="kota_office"
                label={"Kabupaten" + "/" + multiLanguage.city}
              />

              <Select
                // value={kota_office}
                showSearch
                labelInValue
                placeholder={
                  <Label style={{ color: "#5c6873" }}>
                    {"-" +
                      multiLanguage.select +
                      " " +
                      multiLanguage.city +
                      "-"}
                  </Label>
                }
                notFoundContent={fetching ? <Spin size="small" /> : null}
                onChange={this.onChangeOfficeCity("city")}
                style={{ width: "100%" }}
                onSearch={this.onOfficeSearchCity}
                filterOption={false}
              >
                {payloadOfficeCity.map((d) => (
                  <Option key={d.kota_id}>{d.kota_name}</Option>
                ))}
              </Select>
            </Col>

            <Col md="3">
              <LabelRequired
                fors="kecamatan_office"
                label={multiLanguage.district}
              />

              <Select
                // value={kecamatan_office}
                showSearch
                labelInValue
                placeholder={
                  <Label style={{ color: "#5c6873" }}>
                    {"-" +
                      multiLanguage.select +
                      " " +
                      multiLanguage.district +
                      "-"}
                  </Label>
                }
                notFoundContent={fetching ? <Spin size="small" /> : null}
                onChange={this.onChangeOfficeDistrict("district_office")}
                style={{ width: "100%" }}
                onSearch={this.onOfficeSearchDistrict}
                filterOption={false}
              >
                {payloadOfficeDistrict.map((d) => (
                  <Option key={d.kecamatan_id}>{d.kecamatan_name}</Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: "3", offset: 1 }}>
              <LabelRequired
                fors="kelurahan_office"
                label={multiLanguage.village + "/" + "Desa"}
              />

              <Select
                // value={kelurahan_office}
                showSearch
                labelInValue
                placeholder={
                  <Label style={{ color: "#5c6873" }}>
                    {"-" +
                      multiLanguage.select +
                      " " +
                      multiLanguage.village +
                      "/" +
                      "Desa" +
                      "-"}
                  </Label>
                }
                notFoundContent={fetching ? <Spin size="small" /> : null}
                onChange={this.onChangeOfficeVillage("village_office")}
                style={{ width: "100%" }}
                onSearch={this.onOfficeSearchVillage}
                filterOption={false}
              >
                {payloadOfficeVillage.map((d) => (
                  <Option key={d.kelurahan_id}>{d.kelurahan_name}</Option>
                ))}
              </Select>
            </Col>
            <Col md="3">
              <LabelRequired fors="address" label={"Alamat Kantor"} />
              <AvInput
                type="textarea"
                id="alamat_pekerjaan"
                name="alamat_pekerjaan"
                value={this.state.alamat_pekerjaan}
                placeholder={"Alamat Kantor"}
                onChange={this.handleChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: multiLanguage.alertName,
                  },
                }}
              />
            </Col>
          </Row>
          <Divider orientation="left" style={{ color: "black" }} /> */}
          <Row>
            <Col sm={{ size: "3", offset: 1 }}>
              <LabelRequired fors="signature" label={multiLanguage.signature} />

              {this.state.signature == "" && this.state.uploadTTD == "" ? (
                <Button onClick={this.toggleSignature} className="btn-primary">
                  {multiLanguage.signature}
                </Button>
              ) : this.state.uploadTTD != "" ? (
                <div>
                  <img
                    style={{
                      width: 260,
                      height: 110,
                      backgroundColor: "grey",
                    }}
                    src={this.state.uploadTTD.base64}
                  />
                  <h6> {this.state.uploadTTD.filename}</h6>

                  <h6>
                    <Button color="link" onClick={this.toggleSignature}>
                      Ganti ?
                    </Button>
                  </h6>
                </div>
              ) : this.state.signature != "" ? (
                <div>
                  <img
                    style={{
                      width: 260,
                      height: 110,
                      backgroundColor: "grey",
                    }}
                    src={this.state.signature}
                  />

                  <h6>
                    <Button color="link" onClick={this.toggleSignature}>
                      Ganti ?
                    </Button>
                  </h6>
                </div>
              ) : null}
            </Col>
          </Row>

          <Alert
            color="danger"
            hidden={this.state.hidden}
            className="text-center"
          >
            {this.state.message}
          </Alert>
        </AvForm>

        <Modal isOpen={this.state.modalSignature} toggle={this.toggleSignature}>
          <ModalHeader toggle={this.toggleSignature}>
            {multiLanguage.change} {multiLanguage.signature}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <span>{multiLanguage.alertSignature}</span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="9">
                <div
                  style={{
                    backgroundColor: "gray",
                    width: 400,
                    height: 400,
                    marginBottom: "5%",
                  }}
                >
                  <SignatureCanvas
                    ref={(ref) => {
                      this.sigPad = ref;
                    }}
                    penColor="black"
                    canvasProps={{
                      width: 400,
                      height: 400,
                      className: "sigCanvas",
                    }}
                  />
                </div>
                {this.sigPad === {} ? (
                  ""
                ) : (
                  <div>
                    <Button
                      color="warning"
                      onClick={this.clear}
                      style={{ marginRight: "50px" }}
                    >
                      <i className="fa fa-eraser" /> {multiLanguage.clear}
                    </Button>
                    <UploadPicture upload={this.uploadProps} />
                  </div>
                )}
              </Col>
            </Row>
            <p />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.toggleSignature}>
              {multiLanguage.cancel}
            </Button>
            <Button
              type="submit"
              color="success"
              onClick={this.changeSignature}
            >
              <i className="fa fa-check" /> {multiLanguage.submit}
            </Button>
          </ModalFooter>
        </Modal>

        <p />

        <Row style={{ marginTop: 50 }}>
          <Col md={{ size: "auto", offset: 8 }}>
            <Button
              type="submit"
              size="md"
              className="btn-primary-sm"
              onClick={this.handleBack}
            >
              <i className="fa fa-chevron-left"> </i>
              {"Login Page"}
            </Button>

            <Button
              className="btn-submit-primary"
              style={{ marginLeft: "20px" }}
              type="submit"
              size="md"
              color="primary  "
              onClick={this.handleValidation}
            >
              <i className="fa fa-check" /> {"Register"}
            </Button>
          </Col>
        </Row>
      </LoadingOverlay>
    );
  }
}

export default RegistrasiAsesi;
