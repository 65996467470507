import React, { Component } from "react";
import { Label } from "reactstrap";

class LabelPlaceholder extends Component {
  render() {
    const { label, fors } = this.props;

    return (
      <Label style={{ color: "#5c6873", opacity: 0.58 }} for={fors}>
        {label}
      </Label>
    );
  }
}

export default LabelPlaceholder;
